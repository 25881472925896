html,
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  background: linear-gradient(
    308deg,
    rgba(39, 94, 145, 1) 0%,
    rgba(152, 220, 255, 1) 100%
  );
  height: 5px;
  border-radius: 20px;
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
a:link {
  color: black;
  text-decoration: none;
}
