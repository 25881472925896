.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  max-width: 50rem;
  border-radius: 10px 10px 10px 10px;
  margin: 0 auto;
  padding: 30px;
  background-color: white;
  text-align: left;
}

a:link {
  color: black;
  text-decoration: none;
}

a:visited {
  color: black;
}
a:hover {
  color: rgba(39, 94, 145, 1);
}
/* a:active {
  text-decoration: underline;
} */
.button {
  margin: 0 auto;
  background: linear-gradient(
    308deg,
    rgba(39, 94, 145, 1) 0%,
    rgba(152, 220, 255, 1) 100%
  );
  border: none;
  transition: 0.3s;
}
.button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  h2 {
    font-size: 30px;
  }
  h4 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
}
