.rollouts {
  text-align: center;
}
.header {
  font-size: 50px;
  font-weight: 700;
}
.body {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
}
.subtitle {
  font-size: 20px;
  font-weight: 300;
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: 30rem; */
  border-radius: 10px;
  background: white;
  padding: 1rem;
  margin: 1rem;
  color: black;
  text-align: center;
  min-height: 20rem;
}
.cardHeader {
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
}

.backdrop {
  background: linear-gradient(
    308deg,
    rgba(39, 94, 145, 1) 0%,
    rgba(152, 220, 255, 1) 100%
  );
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 3rem;
}

.fade {
  padding: 0;
  margin: 0;
}
.icon {
  margin: 0 auto;
  text-align: center;
  font-size: 30px;
}

/* On mouse-over, add a deeper shadow */
/* .card:hover {
    padding: 1.5rem;
  } */

.container {
  padding: 1.5rem;
  background: white;
  border-radius: 10px;
}
@media (max-width: 990px) {
  .card {
    min-height: auto;
  }
}
@media (max-width: 767px) {
  .header {
    font-size: 30px;
    line-height: normal;
  }
  .body {
    font-size: 16px;
  }
  .subtitle {
    font-size: 16px;
  }
  .card {
    height: auto;
    min-height: auto;
    max-width: 100%;
  }
  .cardHeader {
    font-size: 20px;
  }
  .backdrop {
    padding: 0.3rem;
  }
}
