:root {
  --color-primary: #275e91;
  --color-secondary: #ffffff;
}

.App {
  background-color: var(--color-secondary);
}
.slick-slide div {
  outline: none !important;
}
