.team {
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 1rem;
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  max-width: 50rem;
  border-radius: 10px;
  margin: 0 auto;
  background: linear-gradient(
    308deg,
    rgba(39, 94, 145, 1) 0%,
    rgba(152, 220, 255, 1) 100%
  );
  padding-right: 1rem;
  text-align: left;
  margin: 0.5rem;
}

.image {
  max-width: 100%;
  border-radius: 50%;
  margin-top: -50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  filter: grayscale(100%);
}

/* On mouse-over, add a deeper shadow */
/* .card:hover {
    } */

.container {
  padding: 1.5rem;
  background: white;
  border-radius: 10px;
}

.header {
  font-size: 50px;
  font-weight: 700;
}
.name {
  font-size: 35px;
  font-weight: 700;
}
.position {
  font-size: 20px;
  font-style: italic;
}
.body {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
}

.author {
  padding: 1rem;
  color: white;
  font-size: 25px;
}
.sliderDiv {
  padding: 1rem;
}

@media (max-width: 768px) {
  .header {
    font-size: 30px;
    line-height: normal;
  }
  .body {
    font-size: 16px;
  }
  /* .card {
      padding: 0.5rem;
      margin: 0.5rem;
    }
    .container {
      padding: 1rem;
    } */
  .author {
    font-size: 16px;
    padding: 0.5rem;
  }
  .sliderDiv {
    padding: 0.5rem;
  }
}
