.contact {
  text-align: center;
  margin-top: 5rem;
  border-radius: 10px;
  position: relative;
  z-index: 5;
  background: white;
  padding-bottom: 3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.header {
  font-size: 50px;
  font-weight: 700;
}

.subtitle {
  font-size: 20px;
  font-weight: 300;
}

.form {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 50rem;
  border-radius: 10px;
  padding: 1rem;
  margin: 0 auto;
  background: white;
  margin-bottom: 1rem;
}
.alert {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 50rem;
  border-radius: 10px;
  padding: 1rem;
  margin: 0 auto;
}

.row {
  margin-top: 1rem;
}

.button {
  margin: 0 auto;
  background: linear-gradient(
    308deg,
    rgba(39, 94, 145, 1) 0%,
    rgba(152, 220, 255, 1) 100%
  );
  border: none;
  transition: 0.3s;
}
.button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .header {
    font-size: 30px;
    line-height: normal;
  }
  .subtitle {
    font-size: 16px;
  }
}
