.footer {
  margin-top: -1rem;
  min-height: 15rem;
  background: rgb(39, 94, 145);
  background: linear-gradient(
    308deg,
    rgba(39, 94, 145, 1) 0%,
    rgba(152, 220, 255, 1) 100%
  );
  position: relative;
  z-index: 0;
  padding-top: 3rem;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.wrapper {
  margin: 0 auto;
  width: 60rem;
  padding-bottom: 3rem;
}
.button {
  background: none !important;
  border: none;
  padding: 0 !important;

  /*optional*/
  font-family: arial, sans-serif; /*input has OS specific font-family*/
  color: white;
  cursor: pointer;
}
.button:hover {
  text-decoration: underline;
}
