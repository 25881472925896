.home {
  /* min-height: 60vh; */
  background: rgb(39, 94, 145);
  background: linear-gradient(
    308deg,
    rgba(39, 94, 145, 1) 0%,
    rgba(152, 220, 255, 1) 100%
  );
  /* background: linear-gradient(45.34deg, #ea52f8 5.66%, #0066ff 94.35%); */
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding-top: 20vh;
  padding-bottom: 5vh;
  position: relative;
  z-index: 10;
}
.textWrapper {
  width: 60rem;
  /* display: inline-block; */
}

.header {
  font-size: 50px;
  color: white;
  word-wrap: break-word;
}
.subtitle {
  font-size: 20px;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .header {
    font-size: 30px;
    line-height: normal;
    max-width: 80%;
    margin: 0 auto;
  }
  .subtitle {
    font-size: 16px;
    max-width: 80%;
    margin: 0 auto;
  }
}
