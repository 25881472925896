.navbar {
  /* height: 10vh; */
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* position: fixed; */
  background: white;
  /* backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.7); */
  z-index: 100;
  /* border: 5px solid red;
  border-top: hidden; */
  width: 100%;
}
