.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  max-width: 40rem;
  border-radius: 10px;

  background: linear-gradient(
    308deg,
    rgba(39, 94, 145, 1) 0%,
    rgba(152, 220, 255, 1) 100%
  );
  padding-right: 1rem;
  text-align: left;
  margin: 0.5rem;
  margin: 0 auto;
  margin-top: 10rem;
}

.image {
  max-width: 14rem;
  border-radius: 50%;
  margin-top: -5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  filter: grayscale(100%);
  display: block;
}
.titleWrapper {
  text-align: left;
}

/* On mouse-over, add a deeper shadow */
/* .card:hover {
      } */

.container {
  padding: 1.5rem;
  background: white;
  border-radius: 10px;
}

.name {
  font-size: 35px;
  font-weight: 700;
}
.position {
  font-size: 20px;
  font-style: italic;
}
.body {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .body {
    font-size: 16px;
  }
  .titleWrapper {
    text-align: center;
  }
  .card {
    padding-right: 0rem;
    padding-top: 1rem;
  }
  .name {
    font-size: 20px;
  }
  .position {
    font-size: 16px;
  }
  .image {
    max-width: 10rem;
    margin: auto;
    margin-top: -5rem;
  }
  .container {
    padding: 0.5rem;
  }
}
